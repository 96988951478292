import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import CategoryRowSocialLinks from './CategoryRowSocialLinks';

import { faUnlock } from '@fortawesome/free-solid-svg-icons'; // SSL Unlock icon.
import { faLock }   from '@fortawesome/free-solid-svg-icons'; // SSL lock icon.

export default function CategoryRow(props) {
  const features = props.data.features;
  const data = props.data;
  if (data.isLoading()) return;

  const item = props.item;
  const t = props.t;
  const k = props.k;
  const v = props.v;

  // filters (used here for highlighting)
  const tt = ((data.filters && data.filters.tt) || '').toLowerCase().trim();
  const cc = ((data.filters && data.filters.cc) || '').toLowerCase().trim();

  const rowClasses = [];
  if (item.filtered) rowClasses.push('hide');
  if (item.selected) rowClasses.push('selected');

  // setup defaults for this tree..
  let n, kvn, tags, qid, overpassQuery;

  if (t === 'brands') {
    n = item.tags.brand || item.tags.name;
    if (n != null)
      n = n.replaceAll('"','\\\"');
    kvn = `${k}/${v}|${n}`;
    tags = item.tags || {};
    qid = tags['brand:wikidata'];
    let bn = tags['brand'];
    overpassQuery = `[out:json][timeout:100];
(nwr["name"="${n}"];);
out center;

{{style:
node,
way,
relation
{ color:red; fill-color:red; }
node[${k}=${v}],
way[${k}=${v}],
relation[${k}=${v}]
{ color:yellow; fill-color:yellow; }
node[${k}=${v}][brand=${bn}][brand:wikidata=${qid}],
way[${k}=${v}][brand=${bn}][brand:wikidata=${qid}],
relation[${k}=${v}][brand=${bn}][brand:wikidata=${qid}]
{ color:green; fill-color:green; }
}}`;

  } else if (t === 'flags') {
    n = item.tags['flag:name'];
    if (n != null)
      n = n.replaceAll('"','\\\"');
    kvn = `${k}/${v}|${n}`;
    tags = item.tags || {};
    qid = tags['flag:wikidata'];
    overpassQuery = `[out:json][timeout:100];
(nwr["flag:name"="${n}"];);
out center;`;

  } else if (t === 'operators') {
    n = item.tags.operator;
    if (n != null)
      n = n.replaceAll('"','\\\"');
    kvn = `${k}/${v}|${n}`;
    tags = item.tags || {};
    qid = tags['operator:wikidata'];
    overpassQuery = `[out:json][timeout:100];
(nwr["operator"="${n}"];);
out center;

{{style:
node,
way,
relation
{ color:red; fill-color:red; }
node[${k}=${v}],
way[${k}=${v}],
relation[${k}=${v}]
{ color:yellow; fill-color:yellow; }
node[${k}=${v}][operator:wikidata=${qid}],
way[${k}=${v}][operator:wikidata=${qid}],
relation[${k}=${v}][operator:wikidata=${qid}]
{ color:green; fill-color:green; }
}}`;

  } else if (t === 'transit') {
    n = item.tags.network;
    if (n != null)
      n = n.replaceAll('"','\\\"');
    kvn = `${k}/${v}|${n}`;
    tags = item.tags || {};
    qid = tags['network:wikidata'];
    overpassQuery = `[out:json][timeout:100];
(nwr["network"="${n}"];);
out body;
>;
out skel qt;

{{style:
node,
way,
relation
{ color:red; fill-color:red; }
node[${k}=${v}],
way[${k}=${v}],
relation[${k}=${v}]
{ color:yellow; fill-color:yellow; }
node[${k}=${v}][network:wikidata=${qid}],
way[${k}=${v}][network:wikidata=${qid}],
relation[${k}=${v}][network:wikidata=${qid}]
{ color:green; fill-color:green; }
}}`;
  }

  const wd = data.wikidata[qid] || {};
  const label = wd.label || '';
  const description = wd.description ? '"' + wd.description + '"' : '';
  const identities = wd.identities || {};
  const logos = wd.logos || {};

  if (t === 'flags') {
    return (
      <tr className={rowClasses.join(' ') || null} >
      <td className='namesuggest'>
        <h3 className='slug' id={item.slug}>
          <a href={`#${item.slug}`}>#</a>
          <span className='anchor'>{item.displayName}</span>
        </h3>
        <div className='nsikey'><pre>{item.id}</pre></div>
        <div className='locations'>{ locoDisplay(item.locationSet, n) }</div>
        <div className='viewlink'>
          { searchOverpassLink(n, overpassQuery) }<br/>
          { searchGoogleLink(n) }<br/>
          <strong>Search:&nbsp;</strong>
          { searchWikipediaLink(n) }
          &nbsp;/&nbsp;
          { searchWikidataLink(n) }
        </div>
      </td>
      <td className='tags'><pre className='tags' dangerouslySetInnerHTML={ highlight(tt, displayTags(tags)) } /></td>
      <td className='wikidata'>
        <h3>{label}</h3>
        <span>{description}</span><br/>
        { wdLink(qid) }
        { siteLink(identities.website) }
        { buildOverpassTurbo(item,features,t,k,v) }
      </td>
      <td className='logo'>{ logo(logos.wikidata) }</td>
      </tr>
    );
  } else {
    return (
      <tr className={rowClasses.join(' ') || null} >
      <td className='namesuggest'>
        <h3 className='slug' id={item.slug}>
          <a href={`#${item.slug}`}>#</a>
          <span className='anchor'>{item.displayName}</span>
        </h3>
        <div className='nsikey'><pre>{item.id}</pre></div>
        <div className='locations'>{ locoDisplay(item.locationSet, n) }</div>
        <div className='viewlink'>
          { searchOverpassLink(n, overpassQuery) }<br/>
          { searchGoogleLink(n) }<br/>
          <strong>Search:&nbsp;</strong>
          { searchWikipediaLink(n) }
          &nbsp;/&nbsp;
          { searchWikidataLink(n) }
        </div>
      </td>
      <td className='tags'><pre className='tags' dangerouslySetInnerHTML={ highlight(tt, displayTags(tags)) } /></td>
      <td className='wikidata'>
        <h3>{label}</h3>
        <span>{description}</span><br/>
        { wdLink(qid) }
        { siteLink(identities.website) }
        <CategoryRowSocialLinks {...identities} />
        { buildOverpassTurbo(item,features,t,k,v) }
      </td>
      <td className='logo'>{ logo(logos.wikidata) }</td>
      <td className='logo'>{ fblogo(identities.facebook, logos.facebook) }</td>
      <td className='logo'>{ logo(logos.twitter) }</td>
      </tr>
    );
  }


  function locoDisplay(locationSet, name) {
    const val = JSON.stringify(locationSet);
    const q = encodeURIComponent(val);
    const href = `https://location-conflation.com/?referrer=nsi&locationSet=${q}`;
    const title = `View LocationSet for ${name}`;
    return val && (
      <a target='_blank' href={href} title={title}><code dangerouslySetInnerHTML={ highlight(cc, val) } /></a>
    );
  }


  function highlight(needle, haystack) {
    let html = haystack;
    if (needle) {
      needle = needle.replaceAll('+', '\\+'); // escape the + symbol.
      const re = new RegExp('\(' + needle + '\)', 'gi');
      html = html.replace(re, '<mark>$1</mark>');
    }
    return  { __html: html };
  }


  function searchGoogleLink(name) {
    const q = encodeURIComponent(name);
    const href = `https://google.com/search?q=${q}`;
    const title = `Search Google for ${name}`;
    return (<a target='_blank' href={href} title={title}>Search Google</a>);
  }

  function searchWikipediaLink(name) {
    const q = encodeURIComponent(name);
    const href = `https://google.com/search?q=${q}+site%3Awikipedia.org`;
    const title = `Search Wikipedia for ${name}`;
    return (<a target='_blank' href={href} title={title}>Wikipedia</a>);
  }

  function searchWikidataLink(name) {
    const q = encodeURIComponent(name);
    const href = `https://google.com/search?q=${q}+site%3Awikidata.org`;
    const title = `Search Wikidata for ${name}`;
    return (<a target='_blank' href={href} title={title}>Wikidata</a>);
  }

  function searchOverpassLink(name, overpassQuery) {
    const q = encodeURIComponent(overpassQuery);
    const href = `https://overpass-turbo.eu/?Q=${q}&R`;
    const title = `Search Overpass Turbo for ${n}`;
    return (<a target='_blank' href={href} title={title}>Search Overpass Turbo</a>);
  }

  function fblogo(username, src) {
    return (username && !src) ? <span>Profile restricted</span> : logo(src);
  }


  function logo(src) {
    return src && (
      <img className='logo' src={src}/>
    );
  }

  function wdLink(qid) {
    const href = `https://www.wikidata.org/wiki/${qid}`;
    return qid && (
      <div className='viewlink'>
      <a target='_blank' href={href}>{qid}</a>
      </div>
    );
  }


  function siteLink(href) {
    let FAicon,FAsecure,FAinsecure;
    FAsecure = <span title='ssl web site'><FontAwesomeIcon icon={faLock} size='lg' /></span>;
    FAinsecure = <span title='non-ssl web site'><FontAwesomeIcon icon={faUnlock} size='lg' /></span>;

    if (href)
      FAicon = (href.startsWith("https://")) ? FAsecure : FAinsecure;

    return href && (
      <div className='viewlink'>
      <a target='_blank' href={href}>{href}</a>
      {FAicon}
      </div>
    );
  }

  function displayTags(tags) {
    let result = '';
    Object.keys(tags).forEach(k => {
      result += `${k}=${tags[k]}
`;
    });

    /* Add an <hr/> line break only if addational information will be displayed. */
    if (item.matchNames || item.matchTags || item.note || item.preserveTags || item.fromTemplate)
      result += '<hr/>';
    
    /* Are the items being drawn from a template? 'item.fromTemplate' is set to true in nsi.json if templated. */
    if (item.fromTemplate) {
      let url,text;

      /* Brands */
      if ((t=='brands') && (k=='amenity') && (v=='atm'))
        {url = '/index.html?t=brands&amp;k=amenity&amp;v=bank'; text = '/brands/amenity/bank.json';}

      /* Operators */
      if ((t=='operators') && (k=='leisure') && (v=='nature_reserve'))
        {url = '/index.html?t=operators&amp;k=leisure&amp;v=park'; text = '/operators/leisure/park.json';}

      if ((t=='operators') && (k=='power') && ((v=='minor_line') || (v=='pole') || (v=='tower')))
        {url = '/index.html?t=operators&amp;k=power&amp;v=line'; text = '/operators/power/line.json';}
      if ((t=='operators') && (k=='power') && (v=='transformer'))
        {url = '/index.html?t=operators&amp;k=power&amp;v=substation'; text = '/operators/power/substation.json';}

      if ((t=='operators') && (k=='pipeline') && (v=='substation'))
        {url = '/index.html?t=operators&amp;k=man_made&amp;v=pipeline'; text = 'operators/man_made/pipeline.json';}

      if ((t=='operators') && (k=='man_made') && ((v=='water_tower') || (v=='water_works')))
        {url = '/index.html?t=operators&amp;k=office&amp;v=water_utility'; text = '/operators/office/water_utility.json';}

      /* Transit */
      if ((t=='transit') && (k=='highway') && (v=='bus_stop'))
        {url = '/index.html?t=transit&amp;k=route&amp;v=bus'; text = '/transit/route/bus.json';}

      if ((t=='transit') && (k=='amenity') && (v=='ferry_terminal'))
        {url = '/index.html?t=transit&amp;k=route&amp;v=ferry'; text = '/transit/route/ferry.json';}

      if ((t=='operators') && (k=='amenity') && (v=='post_box')) {
        /* Post Boxes use multiple templates */
        result += '<strong>Master templates:</strong><br/>';
        result += '<a href="/index.html?t=brands&amp;k=amenity&amp;v=post_office">/brands/amenity/post_office.json</a><br/>';
        result += 'Search brands template master for <a href="/index.html?t=brands&amp;k=amenity&amp;v=post_office&amp;tt=' + item.displayName + '">' + item.displayName + '</a><br/>';
        result += '<a href="/index.html?t=operators&amp;k=amenity&amp;v=post_office">/operators/amenity/post_office.json</a><br/>';
        result += 'Search operators template master for <a href="/index.html?t=operators&amp;k=amenity&amp;v=post_office&amp;tt=' + item.displayName + '">' + item.displayName + '</a><br/>';
      } else {
        /* All the rest use a single template */
        result += '<strong>Master template:</strong><br/>';
        result += '<a href="' + url + '">' + text + '</a><br/>';
        result += 'Search template master for <a href="' + url + '&amp;tt=' + item.displayName + '">' + item.displayName + '</a><br/>';
      }
    }

    if (item.matchNames)
      result += '<strong>matchNames</strong>:<br/>' + item.matchNames + '<br/>';
    if (item.matchTags)
      result += '<strong>matchTags</strong>:<br/>' + item.matchTags + '<br/>';
    if (item.note)
      result += '<strong>Note</strong>:<br/>' + item.note + '<br/>';
    if (item.preserveTags)
      result += '<strong>preserveTags</strong>:<br/>' + item.preserveTags;

    return result;
  }

  function buildOverpassTurbo(itemData,features,t,k,v) {
    let locationSet           = itemData.locationSet.include; // locationSet data should always exist as an array.
    let locJSON               = features;
    let matchNames            = "";
    let name                  = "";
    let brand                 = "";
    let brandWikidata         = "";
    let operator              = "";
    let operatorWikidata      = "";
    let styling               = "";
    let searchArea            = ""; // Should remain blank unless searchArea is being used.
    let radius                = "25000"; // 25km radius, same as location-conflation radius.
    let overpassKey           = "";
    let overpassValue         = "";
    let overpassWikidata      = "";
    let OverpassTurboQueryURI = "";
    let OverpassTurboQuery    = "[out:json][timeout:100];\n"

    console.log("== START ===============================================");
//    console.log("t: " + t);
//    console.log("k: " + k);
//    console.log("v: " + v);
    console.log("== %c" + itemData.displayName,"color:green;");
//    console.log("locationSet typeof: " + typeof locationSet);
//    console.log(locationSet);
//    console.log("locationSet[0] typeof: " + typeof locationSet[0]);
//    console.log(locationSet[0]);
//    console.log("locJSON typeof: " + typeof locJSON);
//    console.log(locJSON);
//    console.log("locJSON.features typeof: " + typeof locJSON.features);
//    console.log(locJSON.features);
//    console.log("locJSON.features.length: " + locJSON.features.length);
//    console.log("locJSON.features[0] typeof: " + typeof locJSON.features[0]);
//    console.log(locJSON.features[0]);
//    console.log("locJSON.features[0].id typeof: " + typeof locJSON.features[0].id);
//    console.log(locJSON.features[0].id);
//    console.log("locJSON.features[0].geometry typeof: " + typeof locJSON.features[0].geometry);
//    console.log(locJSON.features[0].geometry);
//    console.log("locJSON.features[0].geometry.coordinates typeof: " + typeof locJSON.features[0].geometry.coordinates);
//    console.log(locJSON.features[0].geometry.coordinates);
console.log(JSON.stringify(locJSON));

    // Build a basic location search if locationSet isn't set to world (001)
    // or doesn't include a custom .geojson file.
      console.log("locationSet[0]: " + locationSet[0] + " (" + typeof locationSet[0] + ")");
    if (locationSet[0] != "001") {
      console.log("locationSet isn't 001 and so isn't global.");
      console.log("locationSet[0]: " + locationSet[0] + " (" + typeof locationSet[0] + ")");
//      if ((locationSet[0] instanceof String) && (locationSet[0].endsWith(".geojson"))) {
//      if (locationSet[0].endsWith(".geojson")) {
      if ((typeof locationSet[0] !== "object") && (locationSet[0].endsWith(".geojson"))) {
        console.log("%cPOLY SEARCH ...","color:red;");
      console.log(locationSet[0] + " (" + typeof locationSet[0] + ")");

        let i,ii,thisJSON;
        for (i=0; i<locJSON.features.length; i++) {
//          console.log(i);
//          console.log(locJSON.features[i].id);
          console.log("i: " + i + ", ID: " + locJSON.features[i].id);
//          console.log(locJSON.features[i].geometry.coordinates);
//          console.log(" ");

          if (locationSet[0] == locJSON.features[i].id) {
            console.log("  " + locationSet[0] + " matches " + locJSON.features[i].id + " so look through coords.");
            console.log("  coord sets: " + locJSON.features[i].geometry.coordinates.length);
            console.log("  coord pairs: " + locJSON.features[i].geometry.coordinates[0].length);
            searchArea  = "(poly:\"";
            console.log("  inside ii loop, main i is: " + i);
            for (ii=0; (ii<locJSON.features[i].geometry.coordinates[0].length-1); ii++) {

              console.log("    ii: " + ii);
              searchArea += locJSON.features[i].geometry.coordinates[0][ii][1];
              searchArea += " ";
              searchArea += locJSON.features[i].geometry.coordinates[0][ii][0];
              console.log("    " + searchArea);
              if (ii<(locJSON.features[i].geometry.coordinates[0].length - 2)) {
                searchArea += " ";
                console.log("    Added End blank.");
              }
            }
            searchArea += "\");\n  /* Search area using: " + locationSet[0] + " */\n";
            console.log("");
            console.log("    Final Poly search query: " + searchArea);
          }
        }
      } else if ((typeof locationSet[0] === "object") && (!isNaN(locationSet[0][0]))) {
        console.log("RADIUS SEARCH ...");

        // locationSet Array within an Array & is a number, so likely GPS / Radius combo.
        // OverpassTurbo uses "around" function, but requires coords to be swapped.
        searchArea = "(around:" + radius + "," + locationSet[0][1] + "," + locationSet[0][0] + ");";
      } else {
        console.log("AREA SEARCH ...");
        searchArea = "(area.searchArea);";
        OverpassTurboQuery += "(\n";

        // Loop through each location, check to see if it's one that
        // OverpassTurbo doesn't recognise, and swap in one that it does.
        let i,thisLocation;
        for (i=0; i<locationSet.length; i++) {
          thisLocation = locationSet[i];

          // Check unsupported locations.
          if (thisLocation == "gb-wls") // change 'gb-wls' to 'Wales'.
            thisLocation = "Wales";
          if (thisLocation == "gb-sct") // change 'gb-sct' to 'Scotland'.
            thisLocation = "Scotland";
          if (thisLocation == "gb-nir") // change 'gb-nir' to 'Northern Ireland'.
            thisLocation = "Northern Ireland";
          if (thisLocation == "conus") // change 'conus' to 'USA'.
            thisLocation = "USA";
          if (thisLocation == "pr") // change 'pr' to 'Puerto Rico'.
            thisLocation = "Puerto Rico";

          // Add 'geocodeArea' for this location
          OverpassTurboQuery += "  {{geocodeArea:" + thisLocation + "}};\n";
        }
        OverpassTurboQuery += ")->.searchArea;\n";
      }
    } else {
      console.log("locationSet is 001 and so is global.");
      searchArea = ";";
    }


    OverpassTurboQuery += "(\n";

    // Include any 'matchNames' as a name search.
    if (itemData.matchNames) {
      matchNames = itemData.matchNames;

      let i,eachMatch;
      eachMatch = "";

      OverpassTurboQuery += "  // matchNames search:\n";
      for (i=0; i<matchNames.length; i++) {
//        OverpassTurboQuery += "  nwr[\"name\"=\"" + matchNames[i] + "\"]" + searchArea + "\n";
        OverpassTurboQuery += "  nwr[~\"^(brand|name|operator)$\"~\"^(" + matchNames[i] + "$)\",i]" + searchArea + "\n";
      }
      OverpassTurboQuery += "\n";
    }

    if (itemData.tags.name)
	name = itemData.tags.name;
    else
	name = "none set";

    if (itemData.tags.operator)
	operator = itemData.tags.operator;
    else
	operator = "none set";

    if (itemData.tags.brand)
	brand = itemData.tags.brand;
    else
	brand = "none set";

    if (itemData.tags['brand:wikidata'])
	brandWikidata = itemData.tags['brand:wikidata'];
    else
	brandWikidata = "none set";

    if (itemData.tags['operator:wikidata'])
	operatorWikidata = itemData.tags['operator:wikidata'];
    else
	operatorWikidata = "none set";

//      OverpassTurboQuery += "  nwr[~\"^(brand|name|operator)$\"~\"" + name + "\"]" + searchArea + "\n";

    if (name != "none set")
//      OverpassTurboQuery += "  nwr[\"name\"=\"" + name + "\"]" + searchArea + "\n";
      OverpassTurboQuery += "  nwr[~\"^(brand|name|operator)$\"~\"^(" + name + ")$\",i]" + searchArea + "\n";
      overpassKey      = "name";       // Set as the word "name".
      overpassValue    = name;         // Set as the value of "name".
    if (brand != "none set")
//      OverpassTurboQuery += "  nwr[\"brand\"=\"" + brand + "\"]" + searchArea + "\n";
      OverpassTurboQuery += "  nwr[~\"^(brand|name|operator)$\"~\"^(" + brand + ")$\",i]" + searchArea + "\n";
      overpassKey      = "brand";       // Set as the word "brand".
      overpassValue    = brand;         // Set as the value of "brand".
      overpassWikidata = brandWikidata; // Set as the value of "brand:wikidata".
    if (operator != "none set") {
//      OverpassTurboQuery += "  nwr[\"operator\"=\"" + operator + "\"]" + searchArea + "\n";
      OverpassTurboQuery += "  nwr[~\"^(brand|name|operator)$\"~\"^(" + operator + ")$\",i]" + searchArea + "\n";
      overpassKey      = "operator";       // Set as the word "operator".
      overpassValue    = operator;         // Set as the value of "operator".
      overpassWikidata = operatorWikidata; // Set as the value of "operator:wikidata".
    }

    OverpassTurboQuery += ");\nout body;\n>;\nout skel qt;\n\n";

    styling += "{{style:\n";
    styling += "  node,way,relation\n";
    styling += "  { color:gray; fill-color:gray; }\n";
    styling += "  /* Gray items match one or more item,*/\n  /* but not all, and could be an incorrect match.*/\n\n";

    styling += "  node[" + k + "=" + v + "][" + overpassKey + "=" + overpassValue + "][" + overpassKey + ":wikidata=" + overpassWikidata + "],\n";
    styling += "  way[" + k + "=" + v + "][" + overpassKey + "=" + overpassValue + "][" + overpassKey + ":wikidata=" + overpassWikidata + "],\n";
    styling += "  relation[" + k + "=" + v + "][" + overpassKey + "=" + overpassValue + "][" + overpassKey + ":wikidata=" + overpassWikidata + "]\n";
    styling += "  { color:blue; fill-color:blue; }\n";
    styling += "  /* Blue items have all the main items listed in the Name Suggeston Index. */\n\n";

    styling += "  node[disused:" + k + "=" + v + "][" + overpassKey + "=" + overpassValue + "][" + overpassKey + ":wikidata=" + overpassWikidata + "],\n";
    styling += "  way[disused:" + k + "=" + v + "][" + overpassKey + "=" + overpassValue + "][" + overpassKey + ":wikidata=" + overpassWikidata + "],\n";
    styling += "  relation[disused:" + k + "=" + v + "][" + overpassKey + "=" + overpassValue + "][" + overpassKey + ":wikidata=" + overpassWikidata + "]\n";
    styling += "  { color:red; fill-color:red; }\n";
    styling += "  /* Red items have all the main items listed in the Name Suggeston Index, but are marked as disused. */\n";
    styling += "}}";

    OverpassTurboQuery += styling;

    console.log("matchNames is a " + typeof matchNames + ", with a length of " + matchNames.length + ", and contains ...");
    console.log(matchNames);

    console.log("locationSet is a " + typeof locationSet + ", with a length of " + locationSet.length + ", and contains ...");
    console.log(locationSet);
    console.log("== FINISH ===============================================");
    console.log(" ");

//    console.log(JSON.stringify(itemData));
//    console.log("Building Overpass Query...");
//    console.log(OverpassTurboQuery);

  OverpassTurboQueryURI  = "https://overpass-turbo.eu/?Q="; // Base URL.
//    OverpassTurboQueryURI  = "https://tyrasd.github.io/overpass-turbo/?Q="; // Base URL (newer UI).
    OverpassTurboQueryURI += encodeURIComponent(OverpassTurboQuery); // Encoded query.
    OverpassTurboQueryURI += "&R"; // Autorun query upon loading.

    return (
      <>
      <p class='display code during dev only, remove me when live'><pre>{OverpassTurboQuery}</pre></p>
      <p><a href={OverpassTurboQueryURI}>Search Overpass Turbo (advance search)</a></p>
      </>
    );
  }
};
